<template>
  <button class="lg:px-[26px] px-[22px] py-3 bg-brand text-white rounded-3xl text-base !leading-5 tracking-wide"
    :disabled="disabled" :type="type">
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>
